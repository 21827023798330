import axios from 'axios'

export default {
    namespaced: true,
    state: {
        // things we keep
        error: '',
        showsearch: 'yes',
        foundmembers: '',
        searchedname: '',
        searchedssn: '',
        memberhistory: [],
        memberid: '',
        member: '',
        showmember: 'no',
        hasdependent: 'no',
        showdependent: 'no',
        dependents: '',
        claims: '',
        hasclaims: 'no',
        showclaims: 'no',
        benefits: '',
        hasbenefits: 'no',
        showbenefits: 'no',
        benefityears: '',
        membernotes: '',
        showmembernotes: 'no',
        notetext: '',
        elementarray: ''
    },

    getters: {
        error (state) {
            return state.error
        },
        searchedname (state) {
            return state.searchedname
        },
        searchedssn (state) {
            return state.searchedssn
        },
        showsearch (state) {
            return state.showsearch
        },
        foundmembers (state) {
            return state.foundmembers
        },
        memberhistory (state) {
            return state.memberhistory
        },
        memberid (state) {
            return state.memberid
        },
        member (state) {
            return state.member
        },

        showmember (state) {
            return state.showmember
        },

        hasdependent (state) {
            return state.hasdependent
        },

        showdependent (state) {
            return state.showdependent
        },

        dependents (state) {
            return state.dependents
        },

        claims (state) {
            return state.claims
        },

        hasclaims (state) {
            return state.hasclaims
        },

        showclaims (state) {
            return state.showclaims
        },
        benefits (state) {
            return state.benefits
        },

        hasbenefits (state) {
            return state.hasbenefits
        },

        showbenefits (state) {
            return state.showbenefits
        },

        benefityears (state) {
            return state.benefityears
        },

        membernotes (state) {
            return state.membernotes
        },

        showmembernotes (state) {
            return state.showmembernotes
        },

        notetext (state) {
            return state.notetext
        },

        elementarray (state) {
            return state.elementarray
        },

    },

    mutations: {
      // changes state items (current state, any data passed)
      SET_SHOWSEARCH (state, showsearch) {
          state.showsearch = showsearch
      },

      SET_FOUNDMEMBERS (state, foundmembers) {
          state.foundmembers = foundmembers
      },

      SET_MEMBERHISTORY (state, memberhistory) {
          state.memberhistory = memberhistory
      },

      SET_MEMBERID (state, memberid) {
          state.memberid = memberid
      },

      SET_MEMBER (state, member) {
          state.member = member
      },

      SET_SHOWMEMBER (state, showmember) {
          state.showmember = showmember
      },

      SET_ERROR (state, error) {
          state.error = error
      },

      SET_SEARCHEDNAME (state, searchedname) {
          state.searchedname = searchedname
      },

      SET_SEARCHEDSSN (state, searchedssn) {
          state.searchedssn = searchedssn
      },

      SET_HASDEPENDENT (state, hasdependent) {
          state.hasdependent = hasdependent
      },

      SET_SHOWDEPENDENT (state, showdependent) {
          state.showdependent = showdependent
      },

      SET_DEPENDENTS (state, dependents) {
          state.dependents = dependents
      },

      SET_CLAIMS (state, claims) {
          state.claims = claims
      },

      SET_HASCLAIMS (state, hasclaims) {
          state.hasclaims = hasclaims
      },

      SET_SHOWCLAIMS (state, showclaims) {
          state.showclaims = showclaims
      },

      SET_BENEFITS (state, benefits) {
          state.benefits = benefits
      },

      SET_HASBENEFITS (state, hasbenefits) {
          state.hasbenefits = hasbenefits
      },

      SET_SHOWBENEFITS (state, showbenefits) {
          state.showbenefits = showbenefits
      },

      SET_BENEFITYEARS (state, benefityears) {
          state.benefityears = benefityears
      },

      SET_MEMBERNOTES (state, membernotes) {
          state.membernotes = membernotes
      },

      SET_SHOWMEMBERNOTES (state, showmembernotes) {
          state.showmembernotes = showmembernotes
      },

      SET_NOTETEXT (state, notetext) {
          state.notetext = notetext
      },

      SET_MEMBERPHOTO (state, member_photo) {
          state.member.member_picture = member_photo;
      },

      SET_ELEMENTARRAY (state, array) {
          state.elementarray = array;
      }



    },

    actions: {
        // from member page search field
        async searchName( { commit }, searchedname ) {
            commit('SET_ERROR', '');
            commit('SET_FOUNDMEMBERS', '');

            if(searchedname) {
                commit('SET_SEARCHEDNAME', searchedname);
                commit('SET_SEARCHEDSSN', '');
            }

            try {
                let response = await axios.post('members/searchname', {'searchedname':searchedname})
                if(response.data.error) {
                    commit('SET_ERROR', response.data.error);

                }
                if(response.data.foundmembers){
                    commit('SET_MEMBER', '');
                    commit('SET_FOUNDMEMBERS', response.data.foundmembers);

                }

            } catch(e) {
                // ERRORS
            }

        },

        // from member page search field
        async searchSSN ( { commit }, searchedssn ) {
            commit('SET_ERROR', '');
            commit('SET_FOUNDMEMBERS', '');

            if(searchedssn) {
                commit('SET_SEARCHEDSSN', searchedssn);
                commit('SET_SEARCHEDNAME', null);
            }

            try {
                let response = await axios.post('members/searchssn', {'searchedssn':searchedssn} )
                if(response.data.error) {
                    commit('SET_ERROR', response.data.error);

                } else {
                    commit('SET_MEMBER', '');
                    commit('SET_FOUNDMEMBERS', response.data);

                }

            } catch(e) {
                // ERRORS
            }

        },

        // select member from member search results
        async selectMember ( { commit }, memberid ) {
            commit('SET_MEMBERID', memberid)

            try {
                let response = await axios.post('member/memberid', {'memberid':memberid} )

                if(response.data.error) {
                    commit('SET_ERROR', response.data.error);
                    return

                } else {
                    commit('SET_MEMBER', response.data.member);
                    commit('SET_HASDEPENDENT', response.data.hasdependent);
                    commit('SET_HASBENEFITS', response.data.hasbenefits);
                    commit('SET_HASCLAIMS', response.data.hasclaims);
                    return
                }

            } catch(e) {
                // ERRORS
            }

        },

        // menu navigation tab change
        setMenuTab( {commit}, setCommitName ) {

            var commitArray = ['SET_SHOWMEMBER','SET_SHOWDEPENDENT','SET_SHOWBENEFITS',
                'SET_SHOWCLAIMS','SET_SHOWMEMBERNOTES'];

            commitArray.forEach(function(item) {
                if(item === setCommitName) {
                    commit(item, 'yes');

                } else {
                    commit(item, 'no');

                }
            });

        },

        setShowSearch ( { commit } ) {
            commit('SET_SEARCHEDSSN', '');
            commit('SET_SEARCHEDNAME', '');
            commit('SET_FOUNDMEMBERS', '');
            commit('SET_MEMBER', '');
            commit('SET_MEMBERID', '');
            commit('SET_SHOWMEMBER', 'no');
            commit('SET_DEPENDENTS', []);
            commit('SET_HASDEPENDENT', 'no');
            commit('SET_SHOWDEPENDENT', 'no');
            commit('SET_BENEFITS', '');
            commit('SET_HASBENEFITS', 'no');
            commit('SET_SHOWBENEFITS', 'no');
            commit('SET_BENEFITYEARS', '');
            commit('SET_CLAIMS', '');
            commit('SET_HASCLAIMS', 'no');
            commit('SET_SHOWCLAIMS', 'no');
            commit('SET_MEMBERNOTES', '');
            commit('SET_SHOWMEMBERNOTES', 'no');
            commit('SET_SHOWSEARCH', 'yes');
            commit('SET_ERROR', '');
        },

        setHideSearch( { commit }) {
            commit('SET_SHOWSEARCH', 'no');
            commit('SET_ERROR', '');
            commit('SET_FOUNDMEMBERS', '');

        },

        hideShowSearch( { commit }) {
            commit('SET_SHOWSEARCH', 'no');

        },

        // checkMemberHistory( { commit }) {
        //     navigator.serviceWorker.addEventListener('message', event => {
        //         if(event.data.memberhistory) {
        //             commit("SET_MEMBERHISTORY", event.data.memberhistory);
        //         } else {
        //             commit("SET_MEMBERHISTORY", []);
        //         }
        //     })
        //
        //     var msg = {
        //         command: 'getCache',
        //         name: '/membersearch'
        //     };
        //
        //     navigator.serviceWorker.controller.postMessage(msg);
        // },

        clearMemberHistory( { commit } ) {
            commit('SET_MEMBERHISTORY', []);

        },

        setMemberHistory( { commit }, a ) {
            commit('SET_MEMBERHISTORY', a);

        },


        async getDependents( { commit, state } ) {
            if(state.dependents.length === 0) {
                try {
                    let response = await axios.post('member/dependents', {'alt_id': state.memberid} )
                    // console.log(response.data);

                    if(response.data.error) {
                        commit('SET_ERROR', response.data.error);
                        return

                    } else if(response.data.dependents) {
                        commit('SET_DEPENDENTS', response.data.dependents);
                        return

                    } else {
                        //
                    }

                } catch(e) {
                    // ERRORS
                }

            }
        },

        async getClaims( { commit, state } ) {
            if(state.claims.length === 0) {
                try {
                    let response = await axios.post('member/claims', {'alt_id': state.memberid} )

                    if(response.data.error) {
                        commit('SET_ERROR', response.data.error);
                        return

                    } else {
                        commit('SET_CLAIMS', response.data.claims);
                        return
                    }

                } catch(e) {
                    // ERRORS
                }

            }

        },

        async getBenefits( { commit, state } ) {
            if(state.benefits.length === 0) {
                try {
                    let response = await axios.post('member/benefits', {'alt_id': state.memberid} )

                    if(response.data.error) {
                        commit('SET_ERROR', response.data.error);
                        return

                    } else {
                        commit('SET_BENEFITS', response.data.benefits);
                        commit('SET_BENEFITYEARS', response.data.benefit_years);
                        return
                    }

                } catch(e) {
                    // ERRORS
                }

            }
        },

        changeBenefitsShowing( {state}, benefityear ) {

            state.benefityears.forEach(function(item) {

                if(item.year == benefityear) {
                    item.expanded = 'yes';

                } else {
                    item.expanded = 'no';

                }

            });
        },

        async getMemberNotes( { commit, state } ) {
            // if(state.membernotes.length === 0) {
                try {
                    let response = await axios.post('member/notes', {'alt_id': state.memberid} )

                    if(response.data.error) {
                        commit('SET_ERROR', response.data.error);
                        return

                    } else {
                        commit('SET_MEMBERNOTES', response.data.membernotes);
                        return
                    }

                } catch(e) {
                    // ERRORS
                }

            // }
        },

        setNoteText( { commit }, notetext) {
            commit('SET_NOTETEXT', notetext);

        },

        async addMemberNote( { commit, state }, user_id ) {

            try {
                let response = await axios.post('member/addnote', {
                        'alt_id': state.memberid,
                        'user_id': user_id,
                        'notetext': state.notetext
                    })

                if(!response.data) {
                    commit('SET_ERROR', response.data.error);
                    return

                }

            } catch(e) {
                // ERRORS
            }

        },

        setMemberPhoto( { commit }, photodata ) {
            commit("SET_MEMBERPHOTO", photodata);
        },

        setElementArray( { commit }, array ) {
            commit("SET_ELEMENTARRAY", array);
        }


    }
}
