import axios from 'axios'

export default {
    namespaced: true,
    state: {
        error: '',
        errormodal: '',
        modaltype: 'new',
        modaltypeoverride: 'no',
        visitationlog: '',
        resultsshopnames: '',
        selectedshopnumber: '',
        selecteddate: '',
        today: '',
        hourfrom: '',
        minutefrom: '',
        hourto: '',
        minuteto: '',
        ampmfrom: '',
        ampmto: '',
        notetext: '',
        notelength: '0',
        logtorecord: '',
        deleteshow: 'no',
        recordno: '',
        recordlno: '',
        filteroptions: '',
        datefilter: ''
    },

    getters: {
        error (state) {
            return state.error
        },

        errormodal (state) {
            return state.errormodal
        },

        modaltype (state) {
            return state.modaltype
        },

        modaltypeoverride (state) {
            return state.modaltypeoverride
        },

        visitationlog (state) {
            return state.visitationlog
        },

        resultsshopnames (state) {
            return state.resultsshopnames
        },

        selectedshopnumber (state) {
            return state.selectedshopnumber
        },

        selecteddate (state) {
            return state.selecteddate
        },

        today (state) {
            return state.today
        },

        logtorecord (state) {
            return state.logtorecord
        },

        hourfrom (state) {
            return state.hourfrom
        },

        minutefrom (state) {
            return state.minutefrom
        },

        hourto (state) {
            return state.hourto
        },

        minuteto (state) {
            return state.minuteto
        },

        ampmfrom (state) {
            return state.ampmfrom
        },

        ampmto (state) {
            return state.ampmto
        },

        notetext (state) {
            return state.notetext
        },

        notelength (state) {
            return state.notelength
        },

        deleteshow (state) {
            return state.deleteshow
        },

        recordno (state) {
            return state.recordno
        },

        recordlno (state) {
            return state.recordlno
        },

        filteroptions (state) {
            return state.filteroptions
        },

        datefilter (state) {
            return state.datefilter
        }

    },

    mutations: {
        SET_ERROR (state, error) {
            state.error = error
        },

        SET_ERRORMODAL (state, errormodal) {
            state.errormodal = errormodal
        },

        SET_MODALTYPE (state, modaltype) {
            state.modaltype = modaltype
        },

        SET_MODALTYPEOVERRIDE (state, modaltypeoverride) {
            state.modaltypeoverride = modaltypeoverride
        },

        SET_VISITATIONLOG (state, visitationlog) {
          state.visitationlog = visitationlog
        },

        SET_RESULTSSHOPNAMES (state, resultsshopnames) {
          state.resultsshopnames = resultsshopnames
        },

        SET_SELECTEDSHOPNUMBER (state, selectedshopnumber) {
          state.selectedshopnumber = selectedshopnumber
        },

        SET_SELECTEDDATE (state, selecteddate) {
          state.selecteddate = selecteddate
        },

        SET_TODAY (state, today) {
          state.today = today
        },

        SET_LOGTORECORD (state, logtorecord) {
          state.logtorecord = logtorecord
        },

        SET_HOURFROM (state, hourfrom) {
          state.hourfrom = hourfrom
        },

        SET_MINUTEFROM (state, minutefrom) {
          state.minutefrom = minutefrom
        },

        SET_HOURTO (state, hourto) {
          state.hourto = hourto
        },

        SET_MINUTETO (state, minuteto) {
          state.minuteto = minuteto
        },

        SET_AMPMFROM (state, ampmfrom) {
          state.ampmfrom = ampmfrom
        },

        SET_AMPMTO (state, ampmto) {
          state.ampmto = ampmto
        },

        SET_NOTETEXT (state, notetext) {
          state.notetext = notetext
        },

        SET_NOTELENGTH (state, notelength) {
          state.notelength = notelength
        },

        SET_DELETESHOW (state, deleteshow) {
          state.deleteshow = deleteshow
        },

        SET_RECORDNO (state, recordno) {
            state.recordno = recordno
        },

        SET_RECORDLNO (state, recordlno) {
            state.recordlno = recordlno
        },

        SET_FILTEROPTIONS (state, filteroptions) {
            state.filteroptions = filteroptions
        },

        SET_DATEFILTER (state, datefilter) {
            state.datefilter = datefilter
        },

    },

    actions: {
        async getVisitationLog ( { state, commit } ) {
            var filter = {
                'datefilter': state.datefilter
            }

            try {
                let response = await axios.post('visitationlog/log', filter)

                if(response.data.visitationlog) {
                    commit('SET_ERROR', '');
                    commit('SET_ERRORMODAL', '');
                    commit('SET_VISITATIONLOG', response.data.visitationlog);
                    return

                }

                if(response.data.error) {
                    commit('SET_VISITATIONLOG', '');
                    commit('SET_ERROR', response.data.error);
                    return

                }

            } catch(e) {
                // ERRORS
            }

        },

        async getFilterOptions( { commit } ) {
            let response = await axios.get('visitationlog/filteroptions')

            if(response.data.filteroptions) {
                commit("SET_FILTEROPTIONS", response.data.filteroptions)
            }

        },

        setDateFilter( { commit }, filter){
            commit("SET_DATEFILTER", filter);
        },

        async searchShopNames( { commit }, shopname ) {
            let response = await axios.post('visitationlog/shopname', { 'searchedshop': shopname })

            if(response.data.shops) {
                commit("SET_RESULTSSHOPNAMES", response.data.shops);
            }

            if(response.data.error) {
                commit("SET_ERRORMODAL", response.data.error);
            }

        },

        clearResultsShopNames( {commit} ) {
            commit("SET_RESULTSSHOPNAMES", '');
        },

        setError( { commit }, msg) {
            commit("SET_ERROR", msg)
        },
        
        setErrorModal( { commit }, msg) {
            commit("SET_ERRORMODAL", msg)
        },

        setModalType( { commit }, t) {
            commit("SET_MODALTYPE", t)
        },

        setModalTypeOverride( { commit }, t) {
            commit("SET_MODALTYPEOVERRIDE", t)
        },

        setResultsShopNames( { commit }, param) {
            commit("SET_RESULTSSHOPNAMES", param)
        },

        setSelectedShopNumber( { commit }, shopno) {
            commit("SET_SELECTEDSHOPNUMBER", shopno)
        },

        setDateToday( { commit }, d) {
            commit("SET_TODAY", d)
        },

        setDateSelected( { commit }, d) {
            commit("SET_SELECTEDDATE", d)
        },

        setLogtoRecord( { commit }, v) {
            commit("SET_LOGTORECORD", v)
        },

        setHourFrom( { commit }, v) {
            commit("SET_HOURFROM", v)
        },

        setMinuteFrom( { commit }, v) {
            commit("SET_MINUTEFROM", v)
        },

        setHourTo( { commit }, v) {
            commit("SET_HOURTO", v)
        },

        setMinuteTo( { commit }, v) {
            commit("SET_MINUTETO", v)
        },

        setAmPmFrom( { commit }, v) {
            commit("SET_AMPMFROM", v)
        },

        setAmPmTo( { commit }, v) {
            commit("SET_AMPMTO", v)
        },

        setNoteText( { commit }, note) {
            var nc = note.length;
            commit("SET_NOTELENGTH", nc)
            commit("SET_NOTETEXT", note)
        },

        setNoteLength( { commit }, l) {
            commit("SET_NOTELENGTH", l)
        },

        setDeleteShow( { commit } , x) {
            commit("SET_DELETESHOW", x)
        },

        setRecordNo( { commit } , n) {
            commit("SET_RECORDNO", n)
        },

        setRecordLNo( { commit } , n) {
            commit("SET_RECORDLNO", n)
        },

        async recordVisitationLogNote({ commit, state }) {
            let response = await axios.post('visitationlog/record', state.logtorecord )

            if(response.data.error) {
                commit("SET_ERRORMODAL", response.data.error);
            }

        },

        async editRecord( { commit, state } ) {
            var fromtime = state.hourfrom+':'+state.minutefrom+' '+state.ampmfrom;
            var totime = state.hourto+':'+state.minuteto+' '+state.ampmto;

            var params = {
                'record_no': state.recordno,
                'record_lno': state.recordlno,
                'selected_date': state.selecteddate,
                'employer_no': state.selectedshopnumber,
                'type': 'edit',
                'time_arrived': fromtime,
                'time_departed': totime,
                'visitation_description': state.notetext

            }

            let response = await axios.post('visitationlog/record', params)

            if(response.data.error) {
                commit("SET_ERRORMODAL", response.data.error);
            }

        },

        async deleteRecord( { commit, state } ) {
            var params = {
                'record_no': state.recordno,
                'record_lno': state.recordlno,
                'selected_date': state.selecteddate
            }

            let response = await axios.post('visitationlog/delete', params)

            if(response.data.error) {
                commit("SET_ERRORMODAL", response.data.error);
            }

        }


    }
}
