import axios from 'axios'

export default {
    namespaced: true,
    state: {
        // things we keep
        error: '',
        homepagedocs: '',
        homepagedocurl: '',
        shoppagedocs: '',
        shoppagedocurl: '',
        previouspage: '',
        showhomeview: 'no',
        showdocumentview: 'yes',
        docname: '',
        docserror: '',
        docsmsg: '',
        count: 0,
        showprogress: false,
        progresscheckinterval: null,
        pagereadycount: 0,
        initialprogresscheck: true,
        pageerror: null

    },

    getters: {
        error(state) {
            return state.error
        },

        homepagedocs(state) {
            return state.homepagedocs
        },

        homepagedocurl(state) {
            return state.homepagedocurl
        },

        shoppagedocs(state) {
            return state.shoppagedocs
        },

        shoppagedocurl(state) {
            return state.shoppagedocurl
        },

        previouspage(state) {
            return state.previouspage
        },

        showhomeview(state) {
            return state.showhomeview
        },

        showdocumentview(state) {
            return state.showdocumentview
        },

        docname(state) {
            return state.docname
        },

        docserror(state) {
            return state.docserror
        },

        docsmsg(state) {
            return state.docsmsg
        },

        count(state) {
            return state.count
        },

        showprogress(state) {
            return state.showprogress
        },

        pagereadycount(state) {
            return state.pagereadycount
        },

        initialprogresscheck(state) {
            return state.initialprogresscheck
        },

        pageerror(state) {
            return state.pageerror
        },

    },

    mutations: {
        // changes state items (current state, any data passed)
        SET_ERROR(state, error) {
            state.error = error
        },

        SET_HOMEPAGEDOCS(state, homepagedocs) {
            state.homepagedocs = homepagedocs
        },

        SET_HOMEPAGEDOCURL(state, homepagedocurl) {
            state.homepagedocurl = homepagedocurl
        },

        SET_SHOPPAGEDOCS(state, shoppagedocs) {
            state.shoppagedocs = shoppagedocs
        },

        SET_SHOPPAGEDOCURL(state, shoppagedocurl) {
            state.shoppagedocurl = shoppagedocurl
        },

        SET_PREVIOUSPAGE(state, previouspage) {
            state.previouspage = previouspage
        },

        SET_SHOWHOMEVIEW(state, showhomeview) {
            state.showhomeview = showhomeview
        },

        SET_SHOWDOCUMENTVIEW(state, showdocumentview) {
            state.showdocumentview = showdocumentview
        },

        SET_DOCNAME(state, docname) {
            state.docname = docname
        },

        SET_DOCSERROR(state, docserror) {
            state.docserror = docserror
        },

        SET_DOCSMSG(state, docsmsg) {
            state.docsmsg = docsmsg
        },

        SET_COUNT(state, count) {
            state.count = count
        },

        SET_SHOWPROGRESS(state, showprogress) {
            state.showprogress = showprogress
        },

        SET_PAGEREADYCOUNT(state, pagereadycount) {
            state.pagereadycount = pagereadycount
        },

        SET_INITIALPROGRESSCHECK(state, initialprogresscheck) {
            state.initialprogresscheck = initialprogresscheck
        },

        SET_PAGEERROR(state, pageerror) {
            state.pageerror = pageerror
        }

    },

    actions: {
        async getHomepageDocs({ commit }) {
            let response = await axios.get('documents/homepagedocs')
            if (response.data.errormsg) {
                commit('SET_DOCSERROR', response.data.errormsg);

            }

            if (response) {
                // console.log(response.data.documents)
                commit('SET_HOMEPAGEDOCS', response.data.documents);

            } else {
                //
            }


        },

        setDoc({ commit }, docname) {
            commit('SET_DOCNAME', docname);
        },

        async getDoc({ commit, state }) {
            let response = await axios.get('documents/getdoc/' + state.docname)
            if (response.data.errormsg) {
                commit('SET_PAGEERROR', response.data.errormsg);
                return;
            } else if (response.data.count) {
                commit('SET_PAGEERROR', null);
                commit('SET_COUNT', response.data.count);
                return;
            }

        },

        async sendDoc({ commit, state }, params) {
            try {
                let response = await axios.post('documents/senddoc', {
                    "memberemail": params.emailaddress,
                    "user_id": params.user_id,
                    "docname": state.docname,
                    "table": state.previouspage
                });

                if (response.data.message === 'success') {
                    commit('SET_DOCSERROR', '');
                    commit('SET_DOCSMSG', "Success");

                    setTimeout(function() {
                        commit('SET_DOCSMSG', '');
                    }, 2000);

                }

                if (response.data.message === 'Server Error') {
                    commit('SET_DOCSMSG', '');
                    commit('SET_DOCSERROR', "Failed to send. Maybe invalid address?");

                }


            } catch (e) {
                commit('SET_DOCSMSG', '');
                commit('SET_DOCSERROR', "Failed to send. Maybe invalid address?");

            }
        },

        setPreviousPage({ commit }, page) {
            commit('SET_PREVIOUSPAGE', page);
        },

        setDocsError({ commit }, msg) {
            commit('SET_DOCSERROR', msg)

            if (msg != '') {
                setTimeout(function() {
                    commit('SET_DOCSERROR', '');
                }, 2000);
            }

        },

        setDocsMsg({ commit }, msg) {
            commit('SET_DOCSMSG', msg)

            if (msg != '') {
                setTimeout(function() {
                    commit('SET_DOCSMSG', '');
                }, 2000);
            }

        },

        setShowProgress({ commit }, msg) {
            commit('SET_SHOWPROGRESS', msg)
        },

        setPageReadyCount({ commit }, msg) {
            commit('SET_PAGEREADYCOUNT', msg)
        },

        setInitialProgressCheck({ commit }, msg) {
            commit('SET_INITIALPROGRESSCHECK', msg)
        },

        setPageError({ commit }, msg) {
            commit('SET_PAGEERROR', msg)
        },

    }


}