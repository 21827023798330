import axios from 'axios'

export default {
    namespaced: true,

    state: {
        error: '',
        usernotehistory: '',
        notehistoryscope: 30,

    },

    getters: {
        error (state) {
            return state.error
        },

        usernotehistory (state) {
            return state.usernotehistory
        },

        notehistoryscope (state) {
            return state.notehistoryscope
        }

    },

    mutations: {
        SET_ERROR (state, error) {
            state.error = error
        },

        SET_USERNOTEHISTORY (state, usernotehistory) {
            state.usernotehistory = usernotehistory
        },

        SET_NOTEHISTORYSCOPE (state, notehistoryscope) {
            state.notehistoryscope = notehistoryscope
        }

    },

    actions: {

        async getNotesHistory( { state, commit }, user ) {
            commit('SET_USERNOTEHISTORY', '');
            commit('SET_ERROR', '');

            try {
                let response = await axios.post('noteshistory', {'user':user, 'scope':state.notehistoryscope})
                if(response.data.error) {
                    commit('SET_ERROR', response.data.error);

                } else {
                    if(response.data.noteshistory) {
                        commit('SET_USERNOTEHISTORY', response.data.noteshistory);
                    }

                }

                // console.log(response.data)
            } catch {
                //
            }

        },

        setNoteHistoryScope( {commit}, scope) {
            commit('SET_NOTEHISTORYSCOPE', scope);

        }

    }

}
