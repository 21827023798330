export default {
    namespaced: true,
    state: {
        // things we keep
        forceportrait: false,
        originalWindowHeight: 0,
        originalWindowWidth: 0
    },

    getters: {
        forceportrait (state) {
            return state.forceportrait
        },

        originalWindowHeight (state) {
            return state.originalWindowHeight
        },

        originalWindowWidth (state) {
            return state.originalWindowWidth
        },

    },

    mutations: {
      // changes state items (current state, any data passed)
      SET_FORCEPORTRAIT (state, forceportrait) {
          state.forceportrait = forceportrait
      },

      SET_ORIGINALWINDOWHEIGHT (state, originalWindowHeight) {
          state.originalWindowHeight = originalWindowHeight
      },

      SET_ORIGINALWINDOWWIDTH (state, originalWindowWidth) {
          state.originalWindowWidth = originalWindowWidth
      },

    },

    actions: {
        turnOnForcePortrait ({ commit }) {
            commit('SET_FORCEPORTRAIT', true)
        },

        turnOffForcePortrait ({ commit }) {
            commit('SET_FORCEPORTRAIT', false)
        },

        setOriginalWindowHeight({ commit }, val) {
            commit('SET_ORIGINALWINDOWHEIGHT', val)
        },

        setOriginalWindowWidth({ commit }, val) {
            commit('SET_ORIGINALWINDOWWIDTH', val)
        }


    }
}
