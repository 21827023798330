export default {
    namespaced: true,
    state: {
        // things we keep
        loadingmsg: false,
        offline: false,
    },

    getters: {
        loadingmsg (state) {
            return state.loadingmsg
        },

        offline (state) {
            return state.offline
        }
    },

    mutations: {
      // changes state items (current state, any data passed)
      SET_LOADINGMSG (state, loadingmsg) {
          state.loadingmsg = loadingmsg
      },

      SET_OFFLINE (state, offline) {
          state.offline = offline
      },


    },

    actions: {
        turnOnLoadingMsg ({ commit }) {
            commit('SET_LOADINGMSG', true)

        },

        turnOffLoadingMsg ({ commit }) {
            commit('SET_LOADINGMSG', false)

        },

        setOffline ({ commit }, msg) {
            commit('SET_OFFLINE', msg)

        },

    }
}
