import axios from 'axios'

export default {
    namespaced: true,
    state: {
        // things we keep
        error: '',
        showshopsearch: 'yes',
        searchedshop: '',
        foundshops: '',
        shophistory: [],
        shop: '',
        showshop: 'no',
        shopcontacts: '',
        hascontacts: 'no',
        showcontacts: 'no',
        shopdivisions: '',
        hasdivisions: 'no',
        showdivisions: 'no',
        shopcontract: '',
        hascontract: 'no',
        showcontract: 'no',
        shopcontracts: '',
        hascontracts: 'no',
        showcontracts: 'no',
        hasshopmembers: 'no',
        showshopmembers: 'no',
        shopmembers: '',
        shopnotes: '',
        showshopnotes: 'no',
        shopnotetext: '',

    },

    getters: {
        error(state) {
            return state.error
        },

        showshopsearch(state) {
            return state.showshopsearch
        },

        searchedshop(state) {
            return state.searchedshop
        },

        foundshops(state) {
            return state.foundshops
        },

        shophistory(state) {
            return state.shophistory
        },

        shop(state) {
            return state.shop
        },

        showshop(state) {
            return state.showshop
        },

        shopcontacts(state) {
            return state.shopcontacts
        },

        hascontacts(state) {
            return state.hascontacts
        },

        showcontacts(state) {
            return state.showcontacts
        },

        shopdivisions(state) {
            return state.shopdivisions
        },

        hasdivisions(state) {
            return state.hasdivisions
        },

        showdivisions(state) {
            return state.showdivisions
        },

        shopcontract(state) {
            return state.shopcontract
        },

        hascontract(state) {
            return state.hascontract
        },

        showcontract(state) {
            return state.showcontract
        },

        shopcontracts(state) {
            return state.shopcontracts
        },

        hascontracts(state) {
            return state.hascontracts
        },

        showcontracts(state) {
            return state.showcontracts
        },

        hasshopmembers(state) {
            return state.hasshopmembers
        },

        showshopmembers(state) {
            return state.showshopmembers
        },

        shopmembers(state) {
            return state.shopmembers
        },

        shopnotes(state) {
            return state.shopnotes
        },

        showshopnotes(state) {
            return state.showshopnotes
        },

        shopnotetext(state) {
            return state.shopnotetext
        },

    },

    mutations: {
        // changes state items (current state, any data passed)
        SET_ERROR(state, error) {
            state.error = error
        },

        SET_SHOWSHOPSEARCH(state, showshopsearch) {
            state.showshopsearch = showshopsearch
        },

        SET_SEARCHEDSHOP(state, searchedshop) {
            state.searchedshop = searchedshop
        },

        SET_FOUNDSHOPS(state, foundshops) {
            state.foundshops = foundshops
        },

        SET_SHOPHISTORY(state, shophistory) {
            state.shophistory = shophistory
        },

        SET_SHOP(state, shop) {
            state.shop = shop
        },

        SET_SHOWSHOP(state, showshop) {
            state.showshop = showshop
        },

        SET_SHOPCONTACTS(state, shopcontacts) {
            state.shopcontacts = shopcontacts
        },

        SET_HASCONTACTS(state, hascontacts) {
            state.hascontacts = hascontacts
        },

        SET_SHOWCONTACTS(state, showcontacts) {
            state.showcontacts = showcontacts
        },

        SET_SHOPDIVISIONS(state, shopdivisions) {
            state.shopdivisions = shopdivisions
        },

        SET_HASDIVISIONS(state, hasdivisions) {
            state.hasdivisions = hasdivisions
        },

        SET_SHOWDIVISIONS(state, showdivisions) {
            state.showdivisions = showdivisions
        },

        SET_SHOPCONTRACT(state, shopcontract) {
            state.shopcontract = shopcontract
        },

        SET_HASCONTRACT(state, hascontract) {
            state.hascontract = hascontract
        },

        SET_SHOWCONTRACT(state, showcontract) {
            state.showcontract = showcontract
        },

        SET_SHOPCONTRACTS(state, shopcontracts) {
            state.shopcontracts = shopcontracts
        },

        SET_HASCONTRACTS(state, hascontracts) {
            state.hascontracts = hascontracts
        },

        SET_SHOWCONTRACTS(state, showcontracts) {
            state.showcontracts = showcontracts
        },

        SET_HASSHOPMEMBERS(state, hasshopmembers) {
            state.hasshopmembers = hasshopmembers
        },

        SET_SHOWSHOPMEMBERS(state, showshopmembers) {
            state.showshopmembers = showshopmembers
        },

        SET_SHOPMEMBERS(state, shopmembers) {
            state.shopmembers = shopmembers
        },

        SET_SHOPNOTES(state, shopnotes) {
            state.shopnotes = shopnotes
        },

        SET_SHOWSHOPNOTES(state, showshopnotes) {
            state.showshopnotes = showshopnotes
        },

        SET_SHOPNOTETEXT(state, shopnotetext) {
            state.shopnotetext = shopnotetext
        }

    },

    actions: {

        async searchShopName({ commit }, params) {
            commit('SET_ERROR', '');
            commit('SET_FOUNDSHOPS', '');
            commit('SET_SEARCHEDSHOP', params.searchedshop);

            try {
                let response = await axios.post('shops/searchedshop', params)
                if (response.data.error) {
                    commit('SET_ERROR', response.data.error);

                } else {
                    commit('SET_SHOP', '');
                    commit('SET_FOUNDSHOPS', response.data.shops);

                }

            } catch (e) {
                // ERRORS
            }

        },

        async selectShop({ commit }, params) {
            commit('SET_ERROR', '');
            commit('SET_FOUNDSHOPS', '');

            try {
                let response = await axios.post('shops/shopid', params)
                // console.log(response.data);

                if (response.data.error) {
                    commit('SET_ERROR', response.data.error);

                } else {
                    // console.log(response.data.shop);
                    commit('SET_SHOP', response.data.shop);

                    if (response.data.shopcontacts.length > 0) {
                        commit('SET_SHOPCONTACTS', response.data.shopcontacts);
                        commit('SET_HASCONTACTS', 'yes');

                    }

                    if (response.data.shopdivisions.length > 0) {
                        commit('SET_SHOPDIVISIONS', response.data.shopdivisions);
                        commit('SET_HASDIVISIONS', 'yes');

                    }

                    if (response.data.shopcontract) {
                        commit('SET_SHOPCONTRACT', response.data.shopcontract);
                        commit('SET_HASCONTRACT', 'yes');

                    }

                    if (response.data.shopcontracts.length > 0) {
                        commit('SET_SHOPCONTRACTS', response.data.shopcontracts);
                        commit('SET_HASCONTRACTS', 'yes');

                    }

                    if (response.data.shopmembers.length > 0) {
                        commit('SET_SHOPMEMBERS', response.data.shopmembers);
                        commit('SET_HASSHOPMEMBERS', 'yes');

                    }
                }

            } catch (e) {
                // ERRORS
            }

        },


        // menu navigation tab change
        setMenuTab({ commit }, setCommitName) {

            var commitArray = ['SET_SHOWSHOP', 'SET_SHOWCONTACTS', 'SET_SHOWDIVISIONS',
                'SET_SHOWCONTRACTS', 'SET_SHOWSHOPMEMBERS', 'SET_SHOWSHOPNOTES'];

            commitArray.forEach(function(item) {
                if (item === setCommitName) {
                    commit(item, 'yes');

                } else {
                    commit(item, 'no');

                }
            });

        },

        setHideShopSearch({ commit }) {
            commit('SET_SHOWSHOPSEARCH', 'no');
            commit('SET_ERROR', '');
            commit('SET_FOUNDSHOPS', '');

        },

        setShowShopSearch({ commit }) {
            commit('SET_ERROR', '');
            commit('SET_SEARCHEDSHOP', '');
            commit('SET_FOUNDSHOPS', []);
            commit('SET_SHOP', '');
            commit('SET_SHOWSHOP', '');

            // SHOP CONTACTS
            commit('SET_SHOPCONTACTS', '');
            commit('SET_HASCONTACTS', 'no');
            commit('SET_SHOWCONTACTS', 'no');

            // SHOP DIVISIONS
            commit('SET_SHOPDIVISIONS', '');
            commit('SET_HASDIVISIONS', 'no');
            commit('SET_SHOWDIVISIONS', 'no');

            // SHOP CONTRACT (NOT DOCUMENT, BUT DATA)
            commit('SET_SHOPCONTRACT', '');
            commit('SET_HASCONTRACT', 'no');
            commit('SET_SHOWCONTRACT', 'no');

            // SHOP CONTRACTS (DOCUMENTS)
            commit('SET_SHOPCONTRACTS', '');
            commit('SET_HASCONTRACTS', 'no');
            commit('SET_SHOWCONTRACTS', 'no');

            // SHOP MEMBERS
            commit('SET_SHOPMEMBERS', '');
            commit('SET_HASSHOPMEMBERS', 'no');
            commit('SET_SHOWSHOPMEMBERS', 'no');

            // MISC
            commit('SET_SHOWSHOPNOTES', 'no');
            commit('SET_SHOWSHOPSEARCH', 'yes');
        },

        clearShopHistory({ commit }) {
            commit('SET_SHOPHISTORY', []);
        },

        setShopHistory({ commit }, a) {
            commit('SET_SHOPHISTORY', a);
        },


        async getShopNotes({ commit }, params) {
            // if(state.membernotes.length === 0) {

            try {
                let response = await axios.post('shops/notes', params)

                if (response.data.error) {
                    commit('SET_ERROR', response.data.error);
                    return

                } else {
                    commit('SET_SHOPNOTES', response.data.shopnotes);
                    return
                }

            } catch (e) {
                // ERRORS
            }

            // }
        },

        setShopNoteText({ commit }, shopnotetext) {
            commit('SET_SHOPNOTETEXT', shopnotetext);

        },

        async addShopNote({ commit, state }, user_id) {

            try {
                let response = await axios.post('shops/addnote', {
                    'shop_id': state.shop.employer_no,
                    'user_id': user_id,
                    'shopnotetext': state.shopnotetext
                })

                if (!response.data) {
                    commit('SET_ERROR', response.data.error);
                    return

                }

            } catch (e) {
                // ERRORS
            }

        },


    }


}