import Vue from 'vue'
import VueRouter from 'vue-router'
const SignIn = () => import(/* webpackChunkName: "sign-in" */ '../views/SignIn.vue')
const Password = () => import(/* webpackChunkName: "password" */ '../views/Password.vue')
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
const Docs = () => import(/* webpackChunkName: "docs" */ '../views/Docs.vue')
const Members = () => import(/* webpackChunkName: "members" */ '../views/Members.vue')
const Shops = () => import(/* webpackChunkName: "shops" */ '../views/Shops.vue')
const NotesHistory = () => import(/* webpackChunkName: "noteshistory" */ '../views/NotesHistory.vue')
const VisitationLog = () => import(/* webpackChunkName: "visitationlog" */ '../views/VisitationLog.vue')
const Photo = () => import(/* webpackChunkName: "photo" */ '../views/Photo.vue')
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },

  {
    path: '/signin',
    name: 'signin',
    component: SignIn,
  },

  {
    path: '/password',
    name: 'password',
    component: Password,
  },

  {
    path: '/docs',
    name: 'docs',
    component: Docs,
  },

  {
    path: '/members',
    name: 'members',
    component: Members,
  },

  {
    path: '/shops',
    name: 'shops',
    component: Shops,
  },

  {
    path: '/noteshistory',
    name: 'noteshistory',
    component: NotesHistory,
  },

  {
    path: '/visitationlog',
    name: 'visitationlog',
    component: VisitationLog,
  },

  {
    path: '/photo',
    name: 'photo',
    component: Photo,
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    var storedtoken;
    var storeduser;

    if(store.getters['auth/token'] != undefined) {
        storedtoken = store.getters['auth/token'];
    } else {
        storedtoken = null;
    }

    if(store.getters['auth/user'] != undefined) {
        storeduser = store.getters['auth/user'];
    } else {
        storeduser = '';
    }

    if (to.name != 'password') {
        if (to.name != 'signin') {
            if ((storedtoken == null) || (storeduser === "")) {
                // console.log('router push signin index.js')
                if(from.name === 'signin') {
                    return
                } else {
                    router.push('signin')
                }

            } else {
                // console.log('there is a user and token, index.js')
                store.dispatch('auth/attempt', storedtoken).then((res) => {
                    if(res) { // auth worked
                        if (to.name === 'signin') {
                            return next({
                                name: 'home'
                            })
                        } else {
                            next()
                        }

                    } else { // auth did not work
                        //clear token
                        store.dispatch('auth/setToken', null);
                        return next({
                            name: 'signin'
                        })

                    }

                })
                // internet disconnected or server not responding
                // .catch((e) => { console.log(e) })

            }


        } else {
            next()
        }
    }
    // to password page
    next()

})

// window.onload = function() {
//   handleConnection();
// }
window.addEventListener('online', handleConnection);
window.addEventListener('offline', handleConnection);

function handleConnection() {

  if (navigator.onLine) {
    isReachable(getServerUrl()).then(function(online) {
      if (online) {
        // handle online status
        // console.log('online');
        store.dispatch('loadingmsg/setOffline', false);
        if(navigator.serviceWorker) {
            navigator.serviceWorker.controller.postMessage({ command: 'setCache', name: '/offlinestatus', value: 'false'})
        }
      } else {
        // console.log('no connectivity');
      }
    });
  } else {
    // handle offline status
    // console.log('offline');
    store.dispatch('loadingmsg/setOffline', true);
    if(navigator.serviceWorker) {
        navigator.serviceWorker.controller.postMessage({ command: 'setCache', name: '/offlinestatus', value: 'true'})
    }

  }
}

function isReachable(url) {
  /**
   * Note: fetch() still "succeeds" for 404s on subdirectories,
   * which is ok when only testing for domain reachability.
   *
   * Example:
   *   https://google.com/noexist does not throw
   *   https://noexist.com/noexist does throw
   */
  return fetch(url, { method: 'HEAD', mode: 'no-cors' })
    .then(function(resp) {
      return resp && (resp.ok || resp.type === 'opaque');
    })
    // .catch(function(err) {
    //   // console.warn('[conn test failure]:', err);
    // });
}

function getServerUrl() {
  return window.location.origin;
}

export default router
