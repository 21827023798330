<template>
<div id="app">
    <div v-cloak>
        <TheNavigation />
        <router-view />
    </div>
    <LoadingMsg />
    <ForcePortrait />
</div>
</template>

<script>
const TheNavigation = () => import( /* webpackChunkName: "TheNavigation" */ './components/TheNavigation')
const LoadingMsg = () => import( /* webpackChunkName: "LoadingMsg" */ './components/LoadingMsg')
const ForcePortrait = () => import( /* webpackChunkName: "LoadingMsg" */ './components/ForcePortrait')
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {
        TheNavigation,
        LoadingMsg,
        ForcePortrait,
    },

    computed: {
        ...mapGetters({
            forceportrait: 'forceportrait/forceportrait',
            originalWindowHeight: 'forceportrait/originalWindowHeight',
            originalWindowWidth: 'forceportrait/originalWindowWidth'
        }),

    },

    mounted: function() {
        this.sOWH(window.outerHeight);
        this.sOWW(window.outerWidth);
        window.onresize = this.checkOrientation
    },

    methods: {
        ...mapActions({
            turnOnForcePortrait: 'forceportrait/turnOnForcePortrait',
            turnOffForcePortrait: 'forceportrait/turnOffForcePortrait',
            setOriginalWindowHeight: 'forceportrait/setOriginalWindowHeight',
            setOriginalWindowWidth: 'forceportrait/setOriginalWindowWidth'
        }),

        checkOrientation() {
            var winheight = window.outerHeight;
            var winwidth = window.outerWidth;

            if ((winwidth > winheight) && (winwidth != this.originalWindowWidth)) {
                this.turnOnForcePortrait();
            } else {
                this.turnOffForcePortrait();
            }
        },

        sOWH(val) {
            this.setOriginalWindowHeight(val)
        },

        sOWW(val) {
            this.setOriginalWindowWidth(val)
        }

    }


}
</script>

<style>
html {
    width: 100%;
    background-color: #ededed;
}

#app {
    min-height: 100%;
    max-height: 100%;
}

[v-cloak] {
    display: none !important;
}
</style>
