import "core-js/stable"
import "regenerator-runtime/runtime"
import 'pdfjs-dist/webpack'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import router from './router'
import store from '@/store'
import axios from 'axios'
import './registerServiceWorker'
import 'vue-croppa/dist/vue-croppa.css'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'

require('@/store/subscriber')

axios.defaults.baseURL = '/api/'


Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon)

if(localStorage.getItem('token') != null) {
    // console.log('Local token exists, main.js')
    store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
        new Vue({
            el: '#app',
            router,
            store,
            render: h => h(App)

        })


        axios.interceptors.response.use(function (response) {
            // console.log('intercepted response')
            if(response.data.token) {
                store.dispatch('auth/setToken', response.data.token)

            }
            if(response.data.errormsg) {
                // console.log('main.js '+response.data.error)
                var n = response.data.errormsg.search('Invalid token');
                if(n != -1) {
                    store.dispatch('auth/setError', response.data.errormsg)
                    store.dispatch('auth/setToken', null)
                    router.push('signin').catch(() => {})
                    store.dispatch('loadingmsg/turnOffLoadingMsg')
                }
                return response
            }

            return response;

            }, function(error) {
                return Promise.reject(error);

        })


    })//.catch((e) => { console.log(e) })

} else {
    // console.log('Local token does not exist, main.js')

    new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App)

    })


    axios.interceptors.response.use(function (response) {
        // console.log('intercepted response')
        if(response.data.token) {
            store.dispatch('auth/setToken', response.data.token)

        }
        if(response.data.errormsg) {
            // console.log('main.js '+response.data.errormsg)
            var n = response.data.errormsg.search('Invalid token');
            if(n != -1) {
                store.dispatch('auth/setError', response.data.errormsg)
                store.dispatch('auth/setToken', null)
                router.push('signin').catch(() => {})
                store.dispatch('loadingmsg/turnOffLoadingMsg')

            }
            return response
        }

        return response;

    }, function(error) {
        return Promise.reject(error);

    })

}
