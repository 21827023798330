import axios from 'axios'

export default {
    namespaced: true,
    state: {
        token: null,
        user: '',
        error: null,
        successmsg: null,
        passwordtoken: null,
    },

    getters: {
        authenticated (state) {
            return state.token && state.user
        },

        token (state) {
            return state.token
        },

        user (state) {
            return state.user
        },

        error (state) {
            return state.error
        },

        successmsg (state) {
            return state.successmsg
        },
        passwordtoken (state) {
            return state.passwordtoken
        },

    },

    mutations: {
      // changes state items (current state, any data passed)
      SET_TOKEN (state, token) {
          state.token = token
      },

      SET_USER (state, user) {
          state.user = user
      },

      SET_ERROR (state, error) {
          state.error = error
      },

      SET_SUCCESSMSG (state, successmsg) {
          state.successmsg = successmsg
      },

      SET_PASSWORDTOKEN (state, passwordtoken) {
          state.passwordtoken = passwordtoken
      },

    },

    actions: {
        async signIn ({ commit }, credentials) {

            let response = await axios.post('auth/signin', credentials);
            if(response && response.data.errormsg) {
                commit('SET_ERROR', response.data.errormsg);
                return false

            }

            if (response) {
                commit('SET_ERROR', null);
                commit('SET_USER', response.data.user)
                commit('SET_TOKEN', response.data.access_token);
                return true

            } else {
                //
            }

        },

        async attempt ( { commit, state }, token) {

            if(token) {
                // console.log('there is a token, auth.js')
                commit('SET_TOKEN', token);
            }

            if(!state.token) {
                // console.log('no state token auth.js')
                return false
            }

            if(state.user === '') {
                commit('SET_TOKEN', null);
                // console.log('no state user auth.js')
                return false
            }


            // headers with token set in subscriber.js
            let response = await axios.get('auth/me');
            if(response.data.errormsg) {
                // console.log(response.data.errormsg)
                commit('SET_TOKEN', null);
                commit('SET_ERROR', response.data.errormsg);
                return false

            } else {
                commit('SET_ERROR', null)
                return true
            }


        },

        async signOut ({ commit }) {
            commit('SET_USER', '')
            commit('SET_TOKEN', null)
            commit('SET_ERROR', null)

        },

        setError( { commit }, msg ) {
            commit('SET_ERROR', msg)
            return
        },

        setSuccessMsg( { commit }, msg ) {
            commit('SET_SUCCESSMSG', msg)
            return
        },

        setToken( { commit }, token ) {
            commit('SET_TOKEN', token)
            return
        },


    }
}
