import axios from 'axios'

export default {
    namespaced: true,
    state: {
        error: null,
        passwordtoken: null,
        showemailsubmit: 'yes',
        showcodesubmit: 'no',
        showpasswordsubmit: 'no',
        redirecttosignin: 'no',
        passchar: 'no',
        passdigit: 'no',
        passsymbol: 'no',
        passnotgood: 'no',
        expired: 'no',
    },

    getters: {
        error (state) {
            return state.error
        },

        passwordtoken (state) {
            return state.passwordtoken
        },

        showemailsubmit (state) {
            return state.showemailsubmit
        },

        showcodesubmit (state) {
            return state.showcodesubmit
        },

        showpasswordsubmit (state) {
            return state.showpasswordsubmit
        },

        redirecttosignin (state) {
            return state.redirecttosignin
        },

        passchar (state) {
            return state.passchar
        },

        passdigit (state) {
            return state.passdigit
        },

        passsymbol (state) {
            return state.passsymbol
        },

        passnotgood (state) {
            return state.passnotgood
        },

        expired (state) {
            return state.expired
        },

    },

    mutations: {
      SET_ERROR (state, error) {
          state.error = error
      },

      SET_PASSWORDTOKEN (state, passwordtoken) {
          state.passwordtoken = passwordtoken
      },

      SET_SHOWEMAILSUBMIT (state, showemailsubmit) {
          state.showemailsubmit = showemailsubmit
      },

      SET_SHOWCODESUBMIT (state, showcodesubmit) {
          state.showcodesubmit = showcodesubmit
      },

      SET_SHOWPASSWORDSUBMIT (state, showpasswordsubmit) {
          state.showpasswordsubmit = showpasswordsubmit
      },

      SET_REDIRECTTOSIGNIN (state, redirecttosignin) {
          state.redirecttosignin = redirecttosignin
      },

      SET_PASSCHAR (state, passchar) {
          state.passchar = passchar
      },

      SET_PASSDIGIT (state, passdigit) {
          state.passdigit = passdigit
      },

      SET_PASSSYMBOL (state, passsymbol) {
          state.passsymbol = passsymbol
      },

      SET_PASSNOTGOOD (state, passnotgood) {
          state.passnotgood = passnotgood
      },

      SET_EXPIRED (state, expired) {
          state.expired = expired
      },

    },

    actions: {

        setError( { commit }, msg ) {
            commit('SET_ERROR', msg)
            return
        },

        async getPasswordToken ({ commit }) {
            try {
                let response = await axios.get('password/token');
                commit('SET_PASSWORDTOKEN', response.data.passwordtoken);

            } catch (e) {
                commit('SET_PASSWORDTOKEN', null)
            }

        },

        async submitEmailAddress({ commit, state }, emailaddress) {
            try {
                let response = await axios.post('password/email', {
                        'passwordtoken': state.passwordtoken,
                        'emailaddress': emailaddress
                    })

                if(response.data.notoken === "notoken") {
                    commit('SET_REDIRECTTOSIGNIN', 'yes');
                    return
                }

                if(response.data.bademail === "bademail") {
                    commit('SET_ERROR', 'Email is not in acceptable format.')
                }

                if(response.data.msg) {
                    commit('SET_SHOWEMAILSUBMIT', 'no')
                    commit('SET_SHOWCODESUBMIT', 'yes')
                    return
                }

                if(response.data.error === 'error') {
                    commit('SET_REDIRECTTOSIGNIN', 'yes');
                    return
                }


            } catch (e) {
                commit('SET_REDIRECTTOSIGNIN', 'yes');
                commit('SET_PASSWORDTOKEN', null)
            }


        },

        async submitCode({ commit, state }, resetcode) {
            try {
                let response = await axios.post('password/resetcode', {
                        'passwordtoken': state.passwordtoken,
                        'resetcode': resetcode
                    })

                if(response.data.notoken === "notoken") {
                    commit('SET_REDIRECTTOSIGNIN', 'yes');
                    commit('SET_SHOWEMAILSUBMIT', 'yes')
                    commit('SET_SHOWCODESUBMIT', 'no');
                    commit('SET_SHOWPASSWORDSUBMIT', 'no');
                    return
                }

                if(response.data.expired === "expired") {
                    commit('SET_REDIRECTTOSIGNIN', 'yes');
                    commit('SET_EXPIRED', 'yes');
                    commit('SET_SHOWEMAILSUBMIT', 'yes')
                    commit('SET_SHOWCODESUBMIT', 'no');
                    commit('SET_SHOWPASSWORDSUBMIT', 'no');
                    return
                }

                if(response.data.msg === "success") {
                    commit('SET_ERROR', null)
                    commit('SET_SHOWEMAILSUBMIT', 'no')
                    commit('SET_SHOWCODESUBMIT', 'no');
                    commit('SET_SHOWPASSWORDSUBMIT', 'yes');
                    return
                }

                if(response.data.error) {
                    commit('SET_ERROR', "Sorry, code is incorrect.")
                    return
                }


            } catch (e) {
                commit('SET_REDIRECTTOSIGNIN', 'yes');
                commit('SET_PASSWORDTOKEN', null)
            }


        },

        async submitPassword({ commit, state }, newpassword) {
            try {
                let response = await axios.post('password/resetpassword', {
                        'passwordtoken': state.passwordtoken,
                        'newpassword': newpassword
                    })

                if(response.data.passrestrict) {
                    commit('SET_PASSNOTGOOD', 'yes');
                    return
                }

                if(response.data.notoken === "notoken") {
                    commit('SET_REDIRECTTOSIGNIN', 'yes');
                    commit('SET_ERROR', "error");
                    commit('SET_SHOWEMAILSUBMIT', 'yes');
                    commit('SET_SHOWCODESUBMIT', 'no');
                    commit('SET_SHOWPASSWORDSUBMIT', 'no');
                    commit('SET_PASSCHAR', 'no');
                    commit('SET_PASSDIGIT', 'no');
                    commit('SET_PASSSYMBOL', 'no');
                    commit('SET_PASSNOTGOOD', 'no');
                    return
                }

                if(response.data.msg === "success") {
                    commit('SET_ERROR', null);
                    commit('SET_SHOWEMAILSUBMIT', 'yes');
                    commit('SET_SHOWCODESUBMIT', 'no');
                    commit('SET_SHOWPASSWORDSUBMIT', 'no');
                    commit('SET_PASSCHAR', 'no');
                    commit('SET_PASSDIGIT', 'no');
                    commit('SET_PASSSYMBOL', 'no');
                    commit('SET_PASSNOTGOOD', 'no');
                    commit('SET_REDIRECTTOSIGNIN', 'yes');
                    return
                }

                if(response.data.error) {
                    commit('SET_ERROR', "Sorry, code is incorrect.")
                    return
                }


            } catch (e) {
                commit('SET_REDIRECTTOSIGNIN', 'yes');
                commit('SET_PASSWORDTOKEN', null)
            }


        },

        resetRedirect({commit}) {
            commit('SET_REDIRECTTOSIGNIN', 'no');
            commit('SET_ERROR', null);
            commit('SET_PASSWORDTOKEN', null)
            return
        },

        setPasschar({commit}, msg) {
            commit('SET_PASSCHAR', msg)
            return
        },

        setPassdigit({commit}, msg) {
            commit('SET_PASSDIGIT', msg)
            return
        },

        setPasssymbol({commit}, msg) {
            commit('SET_PASSSYMBOL', msg)
            return
        },

        setPassnotgood({commit}, msg) {
            commit('SET_PASSNOTGOOD', msg)
            return
        },


    }
}
