import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import password from './password'
import shops from './shops'
import members from './members'
import documents from './documents'
import loadingmsg from './loadingmsg'
import forceportrait from './forceportrait'
import noteshistory from './noteshistory'
import visitationlog from './visitationlog'
import photo from './photo'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
      auth,
      password,
      shops,
      members,
      loadingmsg,
      documents,
      forceportrait,
      noteshistory,
      visitationlog,
      photo
  }
})
