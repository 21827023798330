import axios from 'axios'

export default {
    namespaced: true,
    state: {
        photoerror: '',
        showtakepicture: 'yes',
        showretakepicture: 'no',
        showremovepicture: false,
        showzoombuttons: 'no',
        showsavepicture: 'no',
        photodata: '',
        memberaltid: ''
    },

    getters: {

        photoerror (state) {
            return state.photoerror
        },

        showtakepicture (state) {
            return state.showtakepicture
        },

        showretakepicture (state) {
            return state.showretakepicture
        },

        showremovepicture (state) {
            return state.showremovepicture
        },

        showzoombuttons (state) {
            return state.showzoombuttons
        },

        showsavepicture (state) {
            return state.showsavepicture
        },

        photodata (state) {
            return state.photodata
        },

        memberaltid (state) {
            return state.memberaltid
        },



    },

    mutations: {
      // changes state items (current state, any data passed)
      SET_PHOTOERROR (state, photoerror) {
          state.photoerror = photoerror
      },

      SET_SHOWTAKEPICTURE (state, showtakepicture) {
          state.showtakepicture = showtakepicture
      },

      SET_SHOWRETAKEPICTURE (state, showretakepicture) {
          state.showretakepicture = showretakepicture
      },

      SET_SHOWREMOVEPICTURE (state, showremovepicture) {
          state.showremovepicture = showremovepicture
      },

      SET_SHOWZOOMBUTTONS (state, showzoombuttons) {
          state.showzoombuttons = showzoombuttons
      },

      SET_SHOWSAVEPICTURE (state, showsavepicture) {
          state.showsavepicture = showsavepicture
      },

      SET_PHOTODATA (state, photodata) {
          state.photodata = photodata
      },

      SET_MEMBERALTID (state, memberaltid) {
          state.memberaltid = memberaltid
      }



    },

    actions: {
        async uploadPhoto( { state }) {
            await axios.post('memberphoto/upload',
                {
                    photodata: state.photodata,
                    memberaltid: state.memberaltid
                })

        },

        setShowTakePicture( { commit }, v) {
            commit('SET_SHOWTAKEPICTURE', v);
        },

        setShowRetakePicture( { commit }, v) {
            commit('SET_SHOWRETAKEPICTURE', v);
        },

        setShowRemovePicture( { commit }, v) {
            commit('SET_SHOWREMOVEPICTURE', v);
        },

        setShowZoomButtons( { commit }, v) {
            commit('SET_SHOWZOOMBUTTONS', v);
        },

        setShowSavePicture( { commit }, v) {
            commit('SET_SHOWSAVEPICTURE', v);
        },

        setPhotoData( { commit }, v) {
            commit('SET_PHOTODATA', v);
            return
        },

        setMemberAltId( { commit }, id ) {
            commit('SET_MEMBERALTID', id);
        }


    }


}
